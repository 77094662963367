<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" />
        <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" />
        <!-- End Header Area -->

        <!-- Start Slider Area -->
        <div class="slide slide-style-2 slider-video-bg d-flex align-center justify-center overlay-fix"
            data-black-overlay="1" style="height:30vh !important;">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2 class="heading-title1 text-left font-weight-bold text-capitalize hidden-mobile"
                            style="font-size: 81px !important; line-height: 95px !important; letter-spacing: 2px !important;">
                            Fast Moving Consumer Goods (FMCG)
                        </h2>
                        <h2 class="heading-title1 text-left font-weight-bold text-capitalize hidden-md-and-up"
                            style="font-size: 51px !important;">
                            Fast Moving Consumer Goods (FMCG)
                        </h2>
                    </v-col>
                    <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                        <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light hidden-mobile"
                            style="line-height: 40px !important;">
                            The Pulse of Everyday Life
                        </h5>
                        <h5 class="heading-title1 heading-subtitle text-left font-weight-light hidden-md-and-up"
                            style="line-height: 40px !important; font-size: 20px !important;">
                            The Pulse of Everyday Life
                        </h5>
                    </v-col>
                </v-row>
            </v-container>

            <div class="photo-background hidden-mobile">
                <span>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, rgba(42, 42, 46, 0.50) 50%, rgb(41, 41, 44));"></div>
                    <img src="https://inplatform.sibernetik.co.id/siber-web/images/FMCG.jpg" alt="" style="width: 100%;" />
                </span>
            </div>

            <div class="photo-background hidden-md-and-up">
                <span>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, rgba(42, 42, 46, 0.50) 50%, rgb(41, 41, 44));"></div>
                    <img src="https://inplatform.sibernetik.co.id/siber-web/images/FMCG.jpg" alt=""
                        style="width: 100%; height: 100%;" />
                </span>
            </div>
        </div>
        <!-- End of Slider -->

        <!-- Start Content -->
        <!-- <div class="rn-contact-area rn-section-gap bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-center mb--20">
                            <h4 class="heading-title">Banking & Financial Services</h4>
                            <h3 class="heading-title mb-5">
                                Lorem Ipsum available
                            </h3>
                            <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                            <p style="text-align: center;" class="my-8">PSrE-hub is a pioneering system that allows users to process digital signatures for<br>various type of documents safely and seamlessly which can be integrated with various Certificate Authorities.</p>
                        </div>
                        <ServicePsre/>
                    </div>
                </v-container>
            </div>
        </div>   -->
        <div class="rn-contact-area bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-left mb--20">
                            <h4 class="heading-title">Understanding FMCG</h4>
                            <h3 class="heading-title mb-5">
                                The World of FMCG: Essential, Ubiquitous, Dynamic
                            </h3>
                            <hr color="#dd8732" style="margin: auto;  margin-bottom: 20px; border-top: 4px  solid #dd8732">
                            <v-row>
                                <v-col lg="8" md="12">

                                    <p style="text-align: left; ">
                                        The world of Fast Moving Consumer Goods, commonly known as FMCG, is a bustling arena
                                        that intersects with our daily lives in countless ways. These are the products that
                                        find their way into our homes, our routines, and our conversations. They range from
                                        the most basic necessities like toothpaste and soap to indulgences like chocolates
                                        and perfumes. Their ubiquitous nature makes them integral to our lifestyles, shaping
                                        our choices and preferences.
                                    </p>
                                    <p style="text-align: left; " class="my-8">
                                        In the vast spectrum of industries, FMCG stands out for its dynamism and resilience.
                                        It's an industry characterized by high turnover rates, razor-thin margins, and a
                                        relentless need for innovation. The products, though low in cost and with a short
                                        shelf life, hold immense significance. They are the pulse of the retail world, the
                                        items that consumers reach out for, again and again, making them a goldmine of
                                        opportunities for businesses.
                                    </p>
                                    <p style="text-align: left; " class="my-8">
                                        Yet, the FMCG landscape is not without its challenges. In an era marked by digital
                                        disruptions, changing consumer behaviors, and a global push towards sustainability,
                                        the industry finds itself at a crossroads. Brands are constantly grappling with the
                                        dual need to innovate and stay relevant while ensuring profitability and
                                        sustainability.
                                    </p>
                                </v-col>
                                <v-col lg="4" class="hidden-mobile">
                                    <v-img src="https://inplatform.sibernetik.co.id/siber-web/images/grocery-shopping.jpg"
                                        height="100%" style="padding: 10px !important" />
                                </v-col>
                            </v-row>
                        </div>
                        <!-- <ServicePsre /> -->
                    </div>
                </v-container>
            </div>
        </div>

        <div class="rn-contact-area bg_color--1" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <v-row>
                            <v-col class="hidden-mobile">
                                <v-row>
                                    <v-col lg="6" md="12" sm="12" cols="12" v-for="(service, i) in challenges" :key="i">
                                        <div class="single-service service__style--siber flex"
                                            v-on:mouseenter="onHoverChallenge = service.title"
                                            v-on:mouseleave="onHoverChallenge = ''"
                                            style="display: flex; justify-content: center; align-items: center; padding: 0px !important;">
                                            <v-img :src="service.icon" width="500px" height="300px" />
                                            <div class="bg_color--1 pa-2"
                                                :style="`position: absolute; bottom: 0%; width: 100%;${onHoverChallenge == service.title ? 'color: white; background-color:#dd8732; opacity: 0.9;' : 'opacity: 0.7'}`">
                                                <!-- <div class="icon" v-html="iconSvg(service.icon)"></div> -->
                                                <h4
                                                    :style="`width: 100%; ${onHoverChallenge != service.title ? 'text-align: center' : ''}`">
                                                    {{ service.title }}</h4>
                                                <!-- <div class="content" style="width: 500px;"> -->
                                                <v-expand-transition>
                                                    <p style="font-size: 15px;" v-if="onHoverChallenge == service.title">
                                                        {{ service.desc }}
                                                    </p>
                                                </v-expand-transition>
                                                <!-- </div> -->
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <div>
                                    <h2>Challenges in the FMCG Sector</h2>
                                    <hr color="#dd8732"
                                        style="margin:auto; margin-bottom: 20px; border-top: 4px solid #dd8732">
                                    <p class="whoweare-ket">
                                        In the vibrant world of Fast Moving Consumer Goods, where products seamlessly
                                        integrate into the daily lives of consumers, brands are constantly on their toes,
                                        navigating a complex web of challenges. From understanding the ever-evolving tastes
                                        of consumers to adapting to technological disruptions and global sustainability
                                        demands, the FMCG landscape presents a multifaceted array of hurdles that require
                                        both agility and foresight.
                                    </p>
                                </div>
                            </v-col>
                            <v-col class="hidden-md-and-up" style="scale: 0.9;">
                                <v-row>
                                    <v-col lg="6" md="12" sm="12" cols="12" v-for="(service, i) in challenges" :key="i">
                                        <div class="single-service service__style--siber flex"
                                            v-on:mouseenter="onHoverChallenge = service.title"
                                            v-on:mouseleave="onHoverChallenge = ''"
                                            style="display: flex; justify-content: center; align-items: center; padding: 0px !important;">
                                            <v-img :src="service.icon" width="500px" height="300px" />
                                            <div class="bg_color--1 pa-2"
                                                :style="`position: absolute; bottom: 0%; width: 100%;${onHoverChallenge == service.title ? 'color: white; background-color:#dd8732; opacity: 0.9;' : 'opacity: 0.7'}`">
                                                <!-- <div class="icon" v-html="iconSvg(service.icon)"></div> -->
                                                <h4
                                                    :style="`width: 100%; ${onHoverChallenge != service.title ? 'text-align: center' : ''}`">
                                                    {{ service.title }}</h4>
                                                <!-- <div class="content" style="width: 500px;"> -->
                                                <v-expand-transition>
                                                    <p style="font-size: 15px;" v-if="onHoverChallenge == service.title">
                                                        {{ service.desc }}
                                                    </p>
                                                </v-expand-transition>
                                                <!-- </div> -->
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- End Single Service  -->
                        </v-row>
                    </div>
                </v-container>
            </div>
        </div>

        <div class="rn-contact-area bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-center mb--20">
                            <h4 class="heading-title">Our Solution</h4>
                            <h3 class="heading-title mb-5">
                                Sibernetik's Solutions for the FMCG Industry
                            </h3>
                            <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                            <p style="text-align: center;" class="my-8">In the intricate tapestry of the FMCG sector, where
                                rapid changes and consumer demands intertwine, Sibernetik Integra Data emerges as a beacon
                                of innovation and adaptability. Leveraging our deep expertise in data streaming analytics,
                                security, and cutting-edge enterprise technology, we offer tailored solutions that address
                                the unique challenges of the FMCG landscape. Our approach is not just about integrating
                                technology; it's about crafting holistic strategies that empower FMCG brands to stay ahead
                                of the curve, ensuring they not only meet but exceed consumer expectations in this dynamic
                                market.</p>
                        </div>
                        <v-row class="service-one-wrapper">
                            <!-- Start Single Service  -->
                            <v-col lg="6" md="12" sm="12" cols="12" v-for="(service, i) in solutions" :key="i">
                                <div class="single-service service__style--siber d-flex align-center"
                                    style="height: 200px !important;">
                                    <!-- <router-link to="/service-details"> -->
                                    <div class="service d-flex flex-row">
                                        <div class="icon" v-html="iconSvg(service.icon)"></div>
                                        <div class="content ml-5">
                                            <h3 class="heading-title">{{ service.title }}</h3>
                                            <p>
                                                {{ service.desc }}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- </router-link> -->
                                </div>
                            </v-col>
                            <!-- End Single Service  -->
                        </v-row>
                    </div>
                </v-container>
            </div>
        </div>

        <div class="rn-portfolio-area rn-section-gap bg_color--1" id="recognitionAwards" style="">
            <div class="portfolio-sacousel-inner pb--30">
                <v-container class="fade-in">
                    <v-row>
                        <v-col lg="12">
                            <div class="section-title section-title--2 text-center mb--20">
                                <h3 class="heading-title mb-5">
                                    Trends Shaping the FMCG Future
                                </h3>
                                <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                                <p style="text-align: center; font-size: 24px !important" class="my-8 hidden-mobile">As we
                                    venture deeper
                                    into the 21st century, the FMCG sector stands at the cusp of a transformative era.
                                    Driven by technological advancements, evolving consumer behaviors, and global
                                    socio-economic shifts, a new wave of trends is emerging, poised to redefine the
                                    industry's landscape. These trends are not mere fleeting changes; they are harbingers of
                                    a future where the FMCG sector is more connected, sustainable, and consumer-centric than
                                    ever before. Let's delve into these pivotal shifts that are sculpting the future of
                                    FMCG.
                                </p>
                                <p style="text-align: center; font-size: 20px !important" class="my-8 hidden-md-and-up">As
                                    we venture deeper
                                    into the 21st century, the FMCG sector stands at the cusp of a transformative era.
                                    Driven by technological advancements, evolving consumer behaviors, and global
                                    socio-economic shifts, a new wave of trends is emerging, poised to redefine the
                                    industry's landscape. These trends are not mere fleeting changes; they are harbingers of
                                    a future where the FMCG sector is more connected, sustainable, and consumer-centric than
                                    ever before. Let's delve into these pivotal shifts that are sculpting the future of
                                    FMCG.
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>

                <div class="card-slider hidden-mobile">
                    <div class="buttons" @click="slideLeft" style="cursor: pointer;">
                        <!-- <v-btn @click="slideLeft" color="transparent" elevation="0" flat> -->
                        <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                        <!-- </v-btn> -->
                    </div>
                    <transition-group name="card" tag="div" class="card-container">
                        <v-card :class="'card-solutions-' + index" v-for="(card, index) in trends" :key="card.id">
                            <v-img :src="card.image" height="100%" />
                            <div class="bg_color--1 pa-2"
                                :style="`position: absolute; bottom: 0%; width: 100%;${index == 2 ? 'background-color:#dd8732; opacity: 0.9;padding: 20px 20px 60px 20px !important;' : 'opacity: 0.5; padding: 20px 20px 20px 20px !important;'}`">
                                <!-- <div class="icon" v-html="iconSvg(service.icon)"></div> -->
                                <h3 :style="`width: 100%;`">
                                    {{ card.title }}</h3>
                                <!-- <div class="content" style="width: 500px;"> -->
                                <p style="font-size: 15px;" v-if="index == 2">
                                    {{ card.description }}
                                </p>
                                <!-- </div> -->
                            </div>
                        </v-card>
                        <!-- <Card v-for="(card, index) in trends" :key="card.id" :title="card.title" :year="card.year"
                            :link="card.link" style="position: relative;" /> -->
                    </transition-group>
                    <div class="buttons" @click="slideRight" style="cursor: pointer;">
                        <!-- <v-btn @click="slideRight" color="transparent" elevation="0" flat> -->
                        <v-icon elevation="0" flat x-large>mdi-chevron-right</v-icon>
                        <!-- </v-btn> -->
                    </div>
                </div>
                <div class="card-slider hidden-md-and-up" style="position: relative;">
                    <v-btn @click="slideRight" icon
                        style="background-color: #dd8732;cursor: pointer;top: 50%; right: 0%; position: absolute; z-index: 10;">
                        <v-icon elevation="0" flat x-large>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn @click="slideLeft" icon
                        style="background-color: #dd8732;cursor: pointer; top: 50%; left: 0%; position: absolute; z-index: 10;">
                        <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <!-- <div class="buttons" @click="slideLeft(challenges)"
                                    style="cursor: pointer;left: 0%; position: absolute;">
                                    <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                                </div> -->
                    <v-card
                        :style="`width:100%; height: 500px; transform: translateX(0); transition: transition ease 0.3s; transform: scale(0.9)`">
                        <v-img :src="trends[0].image" height="100%" />
                        <v-btn elevation="0" icon small
                            style="background-color: #dd8732; cursor: pointer; top: 0%; right: 0%; position: absolute; z-index: 10;">
                            {{ trends[0].id }}
                        </v-btn>
                        <div class="bg_color--1 pa-2"
                            :style="`width:100%; height: fit-content; text-break: normal; position: absolute; bottom: 0%; background-color:#dd8732; opacity: 0.9;padding: 20px 20px 60px 20px !important;`">
                            <h3 :style="`width: auto;`">
                                {{ trends[0].title }}</h3>
                            <p style="font-size: 15px;">
                                {{ trends[0].description }}
                            </p>
                        </div>
                    </v-card>
                </div>
            </div>
        </div>

        <div class="rn-contact-area bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-left mb--20">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <h4 class="heading-title">The FMCG industry, With its pulsating energy and
                                            relentless pace</h4>
                                        <h3 class="heading-title mb-5 hidden-mobile">
                                            Stands as a testament to the ever-evolving nature of consumer behavior and
                                            market dynamics.
                                        </h3>
                                        <h3 class="heading-title mb-5 hidden-md-and-up" style="font-size: 30px;">
                                            Stands as a testament to the ever-evolving nature of consumer behavior and
                                            market dynamics.
                                        </h3>
                                        <hr color="#dd8732" style="margin: auto; border-top: 4px  solid #dd8732">
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col lg="6" md="12" sm="12">

                                        <div style="position: relative;">
                                            <v-img
                                                src="https://inplatform.sibernetik.co.id/siber-web/images/marketplace.jpg"
                                                width="auto" class="my-8" height="470px" style="z-index: 0;" />
                                            <v-card
                                                style="position:absolute; width: auto;bottom:0%; z-index: 1; opacity: 0.7; padding: 10px 20px;"
                                                flat tile>
                                                <v-card-title style="word-break: normal;">
                                                    <h4 class="heading-title">We stand on the brink of a new era
                                                    </h4>
                                                    <h3 class="hidden-mobile">
                                                        Marked by digital integrations, sustainability drives
                                                    </h3>
                                                    <h3 class="hidden-md-and-up" style="font-size: 20px;">
                                                        Marked by digital integrations, sustainability drives
                                                    </h3>
                                                </v-card-title>
                                                <v-card-text>
                                                    <p style="text-align: left;">
                                                        And a globalized marketplace, the challenges are many, but so are
                                                        the opportunities.
                                                    </p>
                                                </v-card-text>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col lg="6" md="12" sm="12">

                                        <p style="text-align: left; " class="my-8">
                                            Sibernetik Integra Data, with its deep-rooted expertise and forward-thinking
                                            approach, is not just a spectator in this transformative journey. We are active
                                            participants, catalysts even, driving change and fostering innovation. Our
                                            solutions, tailored for the FMCG sector, are designed to empower brands,
                                            ensuring they remain agile, responsive, and ahead of the curve.
                                        </p>
                                        <p style="text-align: left; " class="my-8">
                                            As we look to the horizon, we envision an FMCG landscape that's more connected,
                                            more sustainable, and more consumer-centric. With Sibernetik Integra Data as a
                                            strategic partner, FMCG brands are equipped to navigate this future, harnessing
                                            the power of technology, analytics, and innovation. Together, we are shaping a
                                            brighter, more dynamic future for the FMCG sector.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                    </div>
                </v-container>
            </div>
        </div>
        <!-- End Content -->

        <Footer />
    </div>
</template>
  
<script>
import HeaderOnePageTwo from "../../../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../../../components/header/HeaderOnePageTwoSiberMobile.vue";
import ServicePsre from "../../../components/service/ServicePsre.vue";
import Footer from "../../../components/footer/Footer";
import Card from '../../../components/portfolio/card.vue';
import feather from "feather-icons";

export default {
    components: {
        HeaderOnePageTwo,
        HeaderOnePageTwoMobile,
        ServicePsre,
        Footer,
        Card,
    },
    data: () => ({
        iconSvg(icon) {
            return feather.icons[icon].toSvg();
        },
        solutions: [
            {
                // icon: "mdi-crosshairs-gps",
                icon: "activity",
                title: "Real-time Data Handling",
                desc: "Empower FMCG brands with instant insights into market trends, optimizing product strategies and enhancing consumer experiences."
            },
            {
                // icon: "mdi-chart-line",
                icon: "code",
                title: "Application Engineering",
                desc: "Offer innovative digital solutions, from personalized shopping experiences to efficient supply chain management."
            },
            {
                // icon: "mdi-alert-outline",
                icon: "shield",
                title: "Robust Security Frameworks",
                desc: " Ensure FMCG digital infrastructures remain fortified, safeguarding sensitive consumer data and ensuring business continuity."
            },
            {
                // icon: "mdi-monitor-multiple",
                icon: "layers",
                title: "Agility with Microservices",
                desc: "Provide agile, modular, and scalable solutions for the dynamic needs of the FMCG industry."

            },
        ],
        trends: [
            {
                id: 0,
                title: 'Direct-to-Consumer (D2C) Models',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/D2C.jpg',
                description: 'The rise of D2C is reshaping the FMCG landscape, offering brands a direct line to their consumers.',
            },
            {
                id: 1,
                title: 'Digital Transformation',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/digital-transformation.jpg',
                description: 'The integration of AI, data analytics, and IoT is revolutionizing the FMCG sector.',
            },
            {
                id: 2,
                title: 'Sustainability and Eco-friendly Practices',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/eco-friendly-product.jpg',
                description: 'FMCG brands are increasingly focusing on sustainable sourcing, eco-friendly packaging, and waste reduction.',
            },
            // Tambahkan data kartu selanjutnya
        ],
        onHoverChallenge: '',
        challenges: [
            {
                icon: "https://inplatform.sibernetik.co.id/siber-web/images/product-packaging.jpg",
                title: "Rapidly Evolving Consumer Preferences",
                desc: "The dynamic nature of consumer tastes and preferences means that brands must be agile and responsive to stay relevant."
            },
            {
                icon: "https://inplatform.sibernetik.co.id/siber-web/images/choosing-products.jpg",
                title: "Intense Competition and Brand Differentiation",
                desc: "With a plethora of brands vying for consumer attention, standing out in the crowded marketplace is a daunting task."
            },
            {
                icon: "https://inplatform.sibernetik.co.id/siber-web/images/packaging.jpg",
                title: "The Need for Sustainable and Eco-friendly Practices",
                desc: "As environmental concerns take center stage, FMCG brands are under pressure to adopt sustainable practices in production, packaging, and distribution."
            },
            {
                icon: "https://inplatform.sibernetik.co.id/siber-web/images/e-commerce.jpg",
                title: "Digital Transformation and E-commerce Integration",
                desc: "The digital age demands that FMCG brands seamlessly integrate online platforms, ensuring a smooth consumer experience from browsing to purchase."
            },
        ],
    }),
    methods: {
        slideLeft() {
            // Metode untuk menggeser kartu ke kiri (Previous)
            const lastCard = this.trends.pop();
            this.trends.unshift(lastCard);
        },
        slideRight() {
            // Metode untuk menggeser kartu ke kanan (Next)
            const firstCard = this.trends.shift();
            this.trends.push(firstCard);
        },
    },
    watch: {

    }
};
</script>
<style scoped>
.heading-title1 {
    color: #dd8732 !important;
    line-height: 60px;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}

.card-slider {
    grid-row: 1 / 2;
    grid-column: 1 / 8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.card-container {
    display: grid;
    align-items: left;
    justify-content: left;
    grid-template-columns: repeat(3, 1fr);
    /* Atur jumlah kolom kartu sesuai kebutuhan */
    grid-gap: 10px;
    /* Jarak antar kartu */
    overflow: hidden;
    /* padding: 20px; */
}

.buttons {
    margin-top: 20px;
    text-align: center;
}

.buttons button {
    margin: 5px;
    padding: 10px 20px;
    /* background-color: #007bff; */
    /* color: #fff; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.buttons button:hover {
    /* background-color: #0056b3; */
    font-weight: 400;
}

.card-solutions-0 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: -20%;
    z-index: 8;
    transform: scale(0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-1 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: 30%;
    z-index: 9;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-2 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: 50%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

/* .card-solutions-3 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    left: -30%;
    z-index: 9;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-4 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    left: -80%;
    z-index: 8;
    transform: scale(0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-5 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    display: none;
} */
</style>
  